import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomQueryService } from '@Services/custom-query.service';
import { SignalRService } from '@Services/signal-r.service';
import { TableService } from '@Services/table.service';
import { ITableData } from '@Models/table-data';
import { sv } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import { BaseChartDirective } from 'ng2-charts';
import { Chart } from 'chart.js';
import { IWidgetSetting } from '@Models/widget-settings';
import { AppService } from '@Services/app.service';
import { ViewService } from '@Services/view.service';

@Component({
  selector: 'app-graph-widget',
  templateUrl: './graph-widget.component.html',
  styleUrls: ['./graph-widget.component.scss']
})
export class GraphWidgetComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  get widget(): IWidgetSetting | undefined { return this.widgetSettings; }
  set widget(w: IWidgetSetting | undefined) { 
    this.widgetSettings = w;
    this.loadWidgetParams();
    if (this.widgetSettings && this.cqParams){
      this.loadCustomQueryData();
    } 
  }

  @Input()
  get customQueryString(): string { return this.cqParams; }
  set customQueryString(params: string) {
    this.cqParams = params;
    if (this.cqParams && this.widgetSettings)
      this.loadCustomQueryData();
  }
  
  @Output() showWidgetClicked: EventEmitter<any> = new EventEmitter<any>();

  public widgetSettings?: IWidgetSetting;
  private cqParams!: string;
  public graphType!: any;
  public dataSource!: any;
  public chartOptions!: any;
  public isLoading!: boolean;
  public isAdmin = false;

  private dataArray!: any[];
  private updateOnTableChanged: string[] = [];
  private signalRsub!: Subscription;
  private chartParameters!: any;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective

  constructor(
    private viewService: ViewService,
    private appService: AppService,
    private queryService: CustomQueryService,
    private signalR: SignalRService) { }

  ngAfterViewInit() {
    if (this.widgetSettings?.dataEndpoint && this.widgetSettings.widgetType === 'graph') {
      this.loadData();
    }
  }

  ngOnInit() {
    this.appService.getMe().subscribe({
      next: (res) => this.isAdmin = res?.isAdministrator ?? false 
    });
  }

  ngOnDestroy() {
    if (this.signalRsub) {
      this.signalRsub.unsubscribe();
    }
  }

  private loadWidgetParams() {
    if (this.widgetSettings?.widgetParameters)
    {
      this.chartParameters = JSON.parse(this.widgetSettings.widgetParameters);
      this.graphType = this.chartParameters.graphType;
    }
  }

  private subscribeToSignalR() {
    if (!this.signalRsub) {
      this.signalRsub = this.signalR.subscribeToUpdates().subscribe({
        next: (res: string) => {
          const changed = res[0];
          if (this.updateOnTableChanged.includes(changed)) {
            this.loadData();
          }
        }
      });
    }
  }

  private loadData() {
    this.dataArray = [];
    this.isLoading = true;
    this.viewService.get(this.widgetSettings!.dataEndpoint).subscribe({
      next: (res: ITableData) => {
        if (res.rows) {
          this.dataArray = res.rows;

          if (res.updateOnTableChanged) {
            this.updateOnTableChanged = res.updateOnTableChanged;
            this.subscribeToSignalR();
          } else
            this.updateOnTableChanged = [];
        } else {
          this.dataArray = [];
        }

        this.createDatasource();
      },
      complete: () => this.isLoading = false
    });
  }

  private loadCustomQueryData() {
    this.dataArray = [];
    this.isLoading = true;

    this.queryService
      .runQuery(this.widgetSettings!.dataEndpoint, this.cqParams)
      .subscribe({
        next: (res) => {
          if (res.rows) {
            this.dataArray = res.rows;

            if (res.updateOnTableChanged) {
              this.updateOnTableChanged = res.updateOnTableChanged;
              this.subscribeToSignalR();
            } else
              this.updateOnTableChanged = [];
          } else {
            this.dataArray = [];
          }

          this.createDatasource();
        },
        complete: () => this.isLoading = false
      });
  }

  private createDatasource() {
    this.dataSource = {
      labels: this.dataArray.map(r => r[this.chartParameters.labelColumn]),
      datasets: [],
      ...this.chartParameters.props,
    };
    this.chartParameters.datasets.forEach((d: any) => {
      let ds = { data: this.dataArray.map(r => r[d.column]), ...d.props };
      this.dataSource.datasets.push(ds);
    });

    if (this.chartParameters?.options?.scales?.x?.type == 'time') {
      this.chartOptions = {
        maintainAspectRatio: false,
        ...this.chartParameters.options,
      };
    } else {
      this.chartOptions = {
        backgroundColor: 'green',
        maintainAspectRatio: false,
        scale: {
          x: {
            adapters: {
              date: {
                locale: sv,
              }
            }
          }
        },
        ...this.chartParameters.options,
      };
    }
  }

  async copyToClipboard() {
    if (this.chart) {
      const base64 = this.chart.toBase64Image();
      if (base64) {
        const type = 'image/png';
        const base64Response = await fetch(base64);
        const blob = await base64Response.blob();
        const data = [new ClipboardItem({ [type]: blob})];
        navigator.clipboard.write(data);
      }
    }
  }

  public openWidgetSettings() {
    this.showWidgetClicked.emit();
  }
}

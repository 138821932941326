import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@Env/environment";
import { ITableData } from "@Models/table-data";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ViewService {

    constructor(private http: HttpClient) {}

    get(endpoint: string) : Observable<ITableData> {
        let realEndpoint = endpoint.replace('-', '/');
        let url = environment.viewUrl + realEndpoint;
        
        return this.http.get<ITableData>(url);
    }
}